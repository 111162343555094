// import React, { useState, useRef, useEffect } from "react";
// import "./home.css";
// import { Button, Form, Row, Col } from "react-bootstrap";
// import { v4 as uuidv4 } from "uuid";

// const Home = () => {
//   const [inputContainers, setInputContainers] = useState([]);
//   const windowRefs = useRef({});

//   const handleAddContainer = () => {
//     const newContainer = { id: uuidv4(), name: "", hours: "", minutes: "", seconds: "" };
//     setInputContainers(prevContainers => [...prevContainers, newContainer]);
//   };

//   const handleRemoveContainer = (id) => {
//     if (windowRefs.current[id] && !windowRefs.current[id].closed) {
//       windowRefs.current[id].close();
//     }
//     setInputContainers(prevContainers =>
//       prevContainers.filter(container => container.id !== id)
//     );
//     delete windowRefs.current[id];
//   };

//   const handleCheckClick = (id) => {
//     const container = inputContainers.find(c => c.id === id);
//     const existingWindow = windowRefs.current[id];

//     if (existingWindow && !existingWindow.closed) {
//       existingWindow.focus();
//     } else {
//       const newWindow = window.open(
//         "/countdown", // Replace with your desired URL or component
//         "_blank",
//         "width=600,height=400"
//       );
//       windowRefs.current[id] = newWindow;

//       // Send data to the child window
//       newWindow.onload = () => {
//         newWindow.postMessage(container, "*");
//       };

//       newWindow.onbeforeunload = () => {
//         delete windowRefs.current[id];
//       };
//     }
//   };

//   const handlePlayPauseStop = (id, action) => {
//     const existingWindow = windowRefs.current[id];
//     if (existingWindow && !existingWindow.closed) {
//       existingWindow.postMessage({ action }, "*");
//     }
//   };

//   const handleInputChange = (id, field, value) => {
//     setInputContainers(prevContainers =>
//       prevContainers.map(container =>
//         container.id === id ? { ...container, [field]: value } : container
//       )
//     );
//   };

//   useEffect(() => {
//     const currentWindowRefs = windowRefs.current;
  
//     return () => {
//       Object.values(currentWindowRefs).forEach(win => {
//         if (win && !win.closed) {
//           win.close();
//         }
//       });
//     };
//   }, []);
  

//   return (
//     <>
//       <h1>Contador de Tempo</h1>
//       <div className="container">
//         <Button
//           variant="success"
//           type="button"
//           onClick={handleAddContainer}
//           className="remover"
//         >
//           <i className="fa fa-plus" aria-hidden="true"></i> Adicionar
//         </Button>
//       </div>

//       {inputContainers.map((container) => (
//         <div className="inputcontainer" key={container.id}>
//           <Form>
//             <Row className="align-items-center">
//               <Col xs={6} className="mb-2">
//                 <Form.Control
//                   placeholder="Nome"
//                   value={container.name}
//                   onChange={(e) => handleInputChange(container.id, "name", e.target.value)}
//                 />
//               </Col>
//               <Col xs={1} className="mb-2">
//                 <Form.Control
//                   placeholder="Horas"
//                   type="number"
//                   value={container.hours}
//                   onChange={(e) => handleInputChange(container.id, "hours", e.target.value)}
//                 />
//               </Col>
//               <Col xs={1} className="mb-2">
//                 <Form.Control
//                   placeholder="Min"
//                   type="number"
//                   value={container.minutes}
//                   onChange={(e) => handleInputChange(container.id, "minutes", e.target.value)}
//                 />
//               </Col>
//               <Col xs={1} className="mb-2">
//                 <Form.Control
//                   placeholder="Seg"
//                   type="number"
//                   value={container.seconds}
//                   onChange={(e) => handleInputChange(container.id, "seconds", e.target.value)}
//                 />
//               </Col>
//               <Col xs={2} className="mb-1 d-flex justify-content-between" >
//                 <Button
//                   variant="outline-primary"
//                   onClick={() => handleCheckClick(container.id)}
//                 >
//                   <i className="fa fa-check" aria-hidden="true"></i>
//                 </Button>
//                 <Button
//                   variant="outline-success"
//                   onClick={() => handlePlayPauseStop(container.id, "play")}
//                   style={{marginLeft:"10px"}}
//                 >
//                   <i className="fa fa-play" aria-hidden="true"></i>
//                 </Button>
//                 <Button
//                   variant="outline-warning"
//                   onClick={() => handlePlayPauseStop(container.id, "pause")}
//                   style={{marginLeft:"10px"}}
//                 >
//                   <i className="fa fa-pause" aria-hidden="true"></i>
//                 </Button>
             
//                 <Button
//                   variant="outline-success"
//                   onClick={() => handlePlayPauseStop(container.id, "save")}
//                   style={{marginLeft:"10px"}}
//                 >
//                   <i className="fa fa-save" aria-hidden="true"></i>
//                 </Button>
//                 <Button
//                   variant="outline-danger"
//                   onClick={() => handleRemoveContainer(container.id)}
//                   style={{marginLeft:"10px"}}
//                 >
//                   <i className="fa fa-trash" aria-hidden="true"></i>
//                 </Button>
//               </Col>
//             </Row>
//           </Form>
//         </div>
//       ))}
//     </>
//   );
// };

// export default Home;

import React, { useState, useRef, useEffect } from "react";
import "./home.css";
import { Button, Form, Row, Col } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const Home = () => {
  const [inputContainers, setInputContainers] = useState([]);
  const windowRefs = useRef({});

  const handleAddContainer = () => {
    const newContainer = { id: uuidv4(), name: "", hours: "", minutes: "", seconds: "" };
    setInputContainers(prevContainers => [...prevContainers, newContainer]);
  };

  const handleRemoveContainer = (id) => {
    if (windowRefs.current[id] && !windowRefs.current[id].closed) {
      windowRefs.current[id].close();
    }
    setInputContainers(prevContainers =>
      prevContainers.filter(container => container.id !== id)
    );
    delete windowRefs.current[id];
  };

  const handleCheckClick = (id) => {
    const container = inputContainers.find(c => c.id === id);
    const existingWindow = windowRefs.current[id];

    if (existingWindow && !existingWindow.closed) {
      existingWindow.focus();
    } else {
      const newWindow = window.open(
        "/countdown",
        "_blank",
        "width=600,height=400"
      );

      windowRefs.current[id] = newWindow;

      newWindow.onload = () => {
        // Ensure the data is sent after the window has fully loaded
        newWindow.postMessage(container, "*");
      };

      newWindow.onbeforeunload = () => {
        delete windowRefs.current[id];
      };
    }
  };

  const handlePlayPauseStop = (id, action) => {
    const existingWindow = windowRefs.current[id];
    if (existingWindow && !existingWindow.closed) {
      existingWindow.postMessage({ action }, "*");
    }
  };

  const handleInputChange = (id, field, value) => {
    setInputContainers(prevContainers =>
      prevContainers.map(container =>
        container.id === id ? { ...container, [field]: value } : container
      )
    );
  };

  useEffect(() => {
    const currentWindowRefs = windowRefs.current;

    return () => {
      Object.values(currentWindowRefs).forEach(win => {
        if (win && !win.closed) {
          win.close();
        }
      });
    };
  }, []);

  return (
    <>
      <h1>Contador de Tempo</h1>
      <div className="container">
        <Button
          variant="success"
          type="button"
          onClick={handleAddContainer}
          className="remover"
        >
          <i className="fa fa-plus" aria-hidden="true"></i> Adicionar
        </Button>
      </div>

      {inputContainers.map((container) => (
        <div className="inputcontainer" key={container.id}>
          <Form>
            <Row className="align-items-center">
              <Col xs={6} className="mb-2">
                <Form.Control
                  placeholder="Nome"
                  value={container.name}
                  onChange={(e) => handleInputChange(container.id, "name", e.target.value)}
                />
              </Col>
              <Col xs={1} className="mb-2">
                <Form.Control
                  placeholder="Horas"
                  type="number"
                  value={container.hours}
                  onChange={(e) => handleInputChange(container.id, "hours", e.target.value)}
                />
              </Col>
              <Col xs={1} className="mb-2">
                <Form.Control
                  placeholder="Min"
                  type="number"
                  value={container.minutes}
                  onChange={(e) => handleInputChange(container.id, "minutes", e.target.value)}
                />
              </Col>
              <Col xs={1} className="mb-2">
                <Form.Control
                  placeholder="Seg"
                  type="number"
                  value={container.seconds}
                  onChange={(e) => handleInputChange(container.id, "seconds", e.target.value)}
                />
              </Col>
              <Col xs={2} className="mb-1 d-flex justify-content-between">
                <Button
                  variant="outline-primary"
                  onClick={() => handleCheckClick(container.id)}
                >
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
                <Button
                  variant="outline-success"
                  onClick={() => handlePlayPauseStop(container.id, "play")}
                  style={{ marginLeft: "10px" }}
                >
                  <i className="fa fa-play" aria-hidden="true"></i>
                </Button>
                <Button
                  variant="outline-warning"
                  onClick={() => handlePlayPauseStop(container.id, "pause")}
                  style={{ marginLeft: "10px" }}
                >
                  <i className="fa fa-pause" aria-hidden="true"></i>
                </Button>
                <Button
                  variant="outline-success"
                  onClick={() => handlePlayPauseStop(container.id, "save")}
                  style={{ marginLeft: "10px" }}
                >
                  <i className="fa fa-save" aria-hidden="true"></i>
                </Button>
                <Button
                  variant="outline-danger"
                  onClick={() => handleRemoveContainer(container.id)}
                  style={{ marginLeft: "10px" }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ))}
    </>
  );
};

export default Home;
