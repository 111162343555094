// import React, { useState, useEffect, useRef } from "react";
// import { CountdownClock } from "../../components/script";
// import "./countdown.css";

// const Countdown = () => {
//   const [value, setValue] = useState({}); // Initialize with an empty object
//   const countdownRef = useRef(null); // Ref to store the CountdownClock instance

//   useEffect(() => {
//     const handleMessage = (event) => {
//       const data = event.data;

//       if (data.name) {
//         console.log("Received data:", data);
//         setValue(data);

//         // Set default values if any of the time fields are empty or invalid
//         const hours = data.hours || 0;
//         const minutes = data.minutes || 0;
//         const seconds = data.seconds || 0;

//         // Instantiate CountdownClock with the validated values
//         countdownRef.current = new CountdownClock(hours, minutes, seconds);

//         // Update the clock display immediately
//         countdownRef.current.updateTime();
//       }

//       if (data.action) {
//         console.log("Received action:", data.action);

//         switch (data.action) {
//           case "play":
//             countdownRef.current?.start(); // Start the timer
//             break;
//           case "pause":
//             countdownRef.current?.pause(); // Pause the timer
//             break;
//           case "stop":
//             countdownRef.current?.stop(); // Stop the timer
//             break;
//           case "save":
//             console.log("save");
//             // Save logic goes here
//             break;
//           default:
//             break;
//         }
//       }
//     };

//     window.addEventListener("message", handleMessage);

//     return () => {
//       window.removeEventListener("message", handleMessage); // Clean up the event listener
//     };
//   }, []);

//   return (
//     <div className="count">
//       <h1>{value.name || "No name provided"}</h1>
//       <div id="clock"></div>
//     </div>
//   );
// };

// export default Countdown;


import React, { useState, useEffect, useRef } from "react";
import { CountdownClock } from "../../components/script";
import "./countdown.css";

const Countdown = () => {
  const [value, setValue] = useState({}); // Initialize with an empty object
  const countdownRef = useRef(null); // Ref to store the CountdownClock instance

  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;

      if (data.name) {
        console.log("Received data:", data);
        setValue(data);

        // Set default values if any of the time fields are empty or invalid
        const hours = data.hours || 0;
        const minutes = data.minutes || 0;
        const seconds = data.seconds || 0;

        // Instantiate CountdownClock with the validated values
        countdownRef.current = new CountdownClock(hours, minutes, seconds);

        // Update the clock display immediately
        countdownRef.current.updateTime();
      }

      if (data.action) {
        console.log("Received action:", data.action);

        switch (data.action) {
          case "play":
            countdownRef.current?.start(); // Start the timer
            break;
          case "pause":
            countdownRef.current?.pause(); // Pause the timer
            break;
          case "stop":
            countdownRef.current?.stop(); // Stop the timer
            break;
          case "save":
            console.log("save");
            // Save logic goes here
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage); // Clean up the event listener
    };
  }, []);

  return (
    <div className="count">
      <h1>{value.name || "No name provided"}</h1>
      <div id="clock"></div>
    </div>
  );
};

export default Countdown;
